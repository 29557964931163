.patternWrapper {
  background-image: linear-gradient(var(--color-gray5) 1px, transparent 1px),
    linear-gradient(to right, var(--color-gray5) 1px, var(--color-gray1) 1px);

  background-size: 20px 20px;
}

.patternWrapperContainer {
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.2) 0%,
    var(--color-gray1) 70%,
    var(--color-gray1) 100%
  );
}
